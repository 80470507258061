<template>
  <ion-page>
     <Header type="0" title="叉车信息" v-if="menuRole == 'phone'"> </Header>
    <ion-content scroll-y="true" id="ProjectMainProjectListOut">
      <div class="root">
        <div class="item">
          <ion-text class="left">
            <span class="required">*</span>调度人</ion-text
          >
          <div class="right" >
            <ion-text>{{ formData.dispatcher }}</ion-text>
            <!-- <span class="iconfont icon-jinru"></span> -->
          </div>
        </div>
        
        <div class="item">
          <div class="item remark">
            <ion-text class="left">
              <span class="required">*</span>用车事由</ion-text
            >
             <div style="max-height:150px;width:100%;overflow:auto">
             <ion-textarea
              auto-grow="true"
              class="right"
              placeholder="请输入..."
              maxlength="500"
              v-model.trim="formData.reason"
            ></ion-textarea>
           </div>
            
          </div>
        </div>
        <div class="item" @click="showPicker = true">
          <div class="left"><span class="required">*</span>紧急程度</div>
          <div class="right">
            <ion-text>{{ formData.urgency }}</ion-text>
            <span class="iconfont icon-jinru"></span>
          </div>
        </div>
        
        <div class="item">
          <ion-text class="left" slot="start"> <span class="required">*</span>开始时间</ion-text>
          <ion-datetime
            class="placeholdercolor"
            placeholder="请选择开始时间"
            v-model="formData.beginTime"
            display-format="YYYY-MM-DD HH:mm"
            :max="formData.endTime"
            cancel-text="取消"
            done-text="确定"
            mode="ios"
          ></ion-datetime>

          <span
            v-show="formData.beginTime"
            @click="resetTime('beginTime')"
            class="iconfont iconzhongzhi"
          ></span>
        </div>

        <div class="item">
          <ion-text class="left"> <span class="required">*</span>结束时间</ion-text>
          <ion-datetime
            class="placeholdercolor"
            placeholder="请选择结束时间"
            v-model="formData.endTime"
            displayFormat="YYYY-MM-DD HH:mm"
            :min="formData.beginTime"
            cancel-text="取消"
            done-text="确定"
            mode="ios"
          ></ion-datetime>
          <span
            v-show="formData.endTime"
            @click="resetTime('endTime')"
            class="iconfont iconzhongzhi"
          ></span>
        </div>
        <div class="item">
          <ion-text class="left"> <span class="required">*</span>手机号</ion-text>
            <div class="right">
              <ion-input
                id="userPhone"
                @click="userPhone"
                placeholder="手机号"
                v-model="formData.applyPersonPhone"
              ></ion-input>
              <span class="iconfont iconjinru"></span>
            </div>
          </div>
          <div class="item" v-if="formData.stateCN">
          <div class="left">状态</div>
          <div class="right">
            <ion-text>{{ formData.stateCN }}</ion-text>
          </div>
        </div>
          <div class="item" v-if="(formData.stateId == 4 && formData.dispatcherId==userId)?true:formData.rejectInfo">
          <div class="item remark">
            <ion-text class="left"> 驳回理由</ion-text>
            <div style="max-height:150px;width:100%;overflow:auto">
             <ion-textarea
              auto-grow="true"
              class="right"
              :placeholder="(formData.stateId == 4 && formData.dispatcherId==userId)?'请输入...':''"
              maxlength="500"
              v-model="formData.rejectInfo"
              :disabled="!(formData.stateId == 4 && formData.dispatcherId==userId)"
            ></ion-textarea>
           </div>
          </div>
        </div>
          <div class="item">
          <div class="item remark">
            <ion-text class="left">用车图片</ion-text>
            <div style="width: 100%">
              <upload-img
                @modelBack="modelBack"
                :maxLength="5"
                :form="formData"
                photo="imageUrl"
                :id="formData.id"
              ></upload-img>
            </div>
          </div>
        </div>
        <!-- <div class="item" @click="openChooseOrganizationModal">
          <div class="left"><span class="required">*</span>代办人</div>
          <div class="right">
            <ion-text></ion-text>
            <span class="iconfont icon-jinru"></span>
          </div>
        </div> -->
      </div>
    </ion-content>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <person-choose @chooseCode="searchPerson"></person-choose>
    <ion-footer>
      <ion-button expand="block" @click="commit">发送叉车调度</ion-button>
    </ion-footer>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import regExps from "@/utils/regExps";
import * as dd from "dingtalk-jsapi";
import {
  getForklift,
  newForklift,
  sendForklift,
  
} from "@/api/carManage/vehicleApplication";
import { menuController, loadingController, alertController } from "@ionic/vue";
import personChoose from "./personChoose.vue";
import { selectDispatch } from "@/api/carManage/vehicleApplication";
import Utils from "@/utils/Utils.ts";
import uploadImg from "@/components/getPhotoVehicle/index.vue";
export default {
  name: "vehicleApplication",
  components: {
    uploadImg,
    Header,
    personChoose,
  },
  data() {
    return {
      formData: {
        type: 1,
        reason:'',
        applyPersonPhone:null,
        imageUrl:[]
      },
      showPicker: false,
      menuRole: "phone",
      columns: ["低(4小时内叉车开始作业)", "中(2小时内叉车开始作业)", "高(1小时内叉车开始作业)"],
    };
  },
  mounted() {
    
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "叉车信息", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    // 带参数时 获取用车信息 
    if(this.$route.query.id){
      this.getForklift(this.$route.query.id);
    }else{
      this.newForklift();
      this.getPerson()
    }
    
    
  },
  methods: {
    modelBack(data){
      this.formData.imageUrl = data;
      
      console.log(this.formData.imageUrl, 4424232);
    },
    userPhone() {
      setTimeout(() => {
        let a = document.querySelector("#userPhone");
        a.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }, 500);
    },
    //清空时间
    resetTime(type) {
      if (type == "beginTime") {
        this.formData.beginTime = "";
      } else if (type == "endTime") {
        this.formData.endTime = "";
      }
    },
    //创建用车
    newForklift() {
      newForklift().then((res) => {
        this.formData.id = res.data.data.id;
      });
    },
     // 查看用车
     getForklift(id) {
      getForklift({ id: id }).then((res) => {
        this.formData = res.data.data.resultData || {};
        this.formData.beginTime =new Date(this.formData.beginTime).toISOString()
        this.formData.endTime =new Date(this.formData.endTime).toISOString();
        this.formData.type = 1;
      });
    },
    //选择级别
    onConfirm(value, index) {
      var _this = this;
      switch (index) {
        case 0:
          _this.formData.urgencyId = 1;
          _this.formData.urgency = "低(4小时内叉车开始作业)";
          break;
        case 1:
          _this.formData.urgencyId = 2;
          _this.formData.urgency = "中(2小时内叉车开始作业)";
          break;
        case 2:
          _this.formData.urgencyId = 3;
          _this.formData.urgency = "高(1小时内叉车开始作业)";
          break;

        default:
          _this.formData.urgencyId = 1;
          _this.formData.urgency = "低(4小时内叉车开始作业)";
          break;
      }
      this.showPicker = false;
    },
    openChooseOrganizationModal() {
      menuController.enable(true, "searchPerson");
      menuController.open("searchPerson");
    },
    //选择调度人
    searchPerson(val) {
      this.formData.dispatcherId = val.id;
      this.formData.dispatcher = val.nameCN;
    },
    // 提交
    async commit() {
      const _this = this;
      if (!regExps.phoneReg.test(this.formData.applyPersonPhone)) {
        Utils.presentToastTopWarning("请输入正确的手机号", "danger");
          return;
        }
       if (!_this.formData.dispatcher) {
                Utils.presentToastTopWarning("请选择调度人", "danger");
                return false;
              } else if (!_this.formData.reason) {
                Utils.presentToastTopWarning("请输入用车事由", "danger");
                return false;
              } else if (!_this.formData.urgency) {
                Utils.presentToastTopWarning("请选择紧急程度", "danger");
                return false;
              } else if (!_this.formData.beginTime) {
                Utils.presentToastTopWarning("请选择开始时间", "danger");
                return false;
              } else if (!_this.formData.endTime) {
                Utils.presentToastTopWarning("请选择结束时间", "danger");
                return false;
              } else if (!_this.formData.applyPersonPhone) {
                Utils.presentToastTopWarning("请输入手机号", "danger");
                return false;
              }

              
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();
             
              _this.formData.beginTime = _this.formatDate(
                _this.formData.beginTime
              );
              _this.formData.endTime = _this.formatDate(_this.formData.endTime);
              sendForklift(Object.assign({},_this.formData,{agentId:localStorage.getItem('agentId')})).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  Utils.presentToastTopWarning("提交成功", "success");
                  _this.$router.go(-1);
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "warning"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    formatDate(date) {
      if (date) {
        var format = new Date(date);
        return (
          format.getFullYear() +
          "-" +
          (format.getMonth() + 1) +
          "-" +
          format.getDate() +
          " " +
          format.getHours() +
          ":" +
          format.getMinutes() +
          ":" +
          format.getSeconds()
        );
      }
      return date;
    },
    getPerson(){
       selectDispatch({
        nameCN: '',
      }).then((res) => {
        this.islist = false;
        var val = res.data.data.resultData? res.data.data.resultData[0]:{};
        /* 调度人id */
        this.formData.dispatcherId = val.id;
         /* 调度人name */
      this.formData.dispatcher = val.nameCN;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.item {
  justify-content: space-between;
}
ion-button {
  --background: #00529c;
}

.datetime-placeholder,
.picker {
  padding: 0 1rem 0 0;
}
.placeholdercolor {
  --placeholder-color: #cbcbce;
  width: calc(100% - #{px2rem(250)});
  padding: 0 1rem 0 0;
  text-align: right;
}
.root .remark .right{
  width: 100%;
}
</style>